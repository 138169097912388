import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  CompanyNameText,
  DetailFormText,
  StyledButton3,
  StyledButton4,
} from "./style";
import { StyledFormLabel } from "../../components/Cores/FormCores/style";
import {
  GridContainer,
  GridItem,
} from "../../components/StyledComponents/Index";
import UploadTextField from "../../components/Forms/RegisterForm/last/Logo";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { apiUrls, help, regularExpressions } from "../../Utilities/properties";
import {
  InputField2,
  PhoneField2,
} from "../../components/Forms/RegisterForm/last/InputFIeld";
import { UpdateProfile, UserDetails } from "../../services/auth.services";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAvatarContext } from "../../context/AvatarContext";
import { CountrySelect2 } from "../../components/Forms/RegisterForm/last/CountrySelect";
import { set } from "lodash";
import { Password } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const ProfileContainer = styled(Container)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(4),
  padding: theme.spacing(4),
  backgroundColor: "white",
  borderRadius: theme.shape.borderRadius,
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

interface Admin {
  first_name: string;
  last_name: string;
  phone_number: string;
  email_address: string;
}

interface Company {
  company_name: string;
  logo: File | null;
  company_reg_no: string;
  company_code:string;
  company_country: string;
}

const ProfileSettings: React.FC = () => {
  const { setUserAvatar, setUserHeaderName } = useAvatarContext();
  const [admin, setAdmin] = useState<Admin>({
    first_name: "",
    email_address: "",
    last_name: "",
    phone_number: "",
  });
  const [company, setCompany] = useState<Company>({
    company_name: "",
    company_reg_no: "",
    company_code: "",
    company_country: "",
    logo: null,
  });
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editState, setEditState] = useState(true);
  const token = localStorage.getItem("token");
  const [errorMsg, setErrorMsg] = useState({new_password:'', current_password:''});
  const [change, setChange] = useState<boolean>(false);

  const navigate = useNavigate();

  const fetchAdmin = async () => {
    try {
      const response = await UserDetails({
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdmin(response.data.user);
      setCompany(response.data.company);
      setPreview(`${apiUrls.BASEURL}/uploads/${response.data.company.logo}`);
      // setPreview('http://127.0.0.1:5000/upload/'.concat(response.data.company.logo));
      setIsLoading(false);
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        console.log("error:", errMsg);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      navigate(apiUrls.LOG_IN);
    }
    fetchAdmin();
  }, [token]);

  const allSchema = yup.object({
    name: yup.string().required("Name " + help.VALUE_REQUIRED),
    phoneNumber: yup
      .string()
      // .matches(regularExpressions.PHONE_REGEX, help.INVALID_CONTACT_NUMBER)
      .required("Phone number " + help.VALUE_REQUIRED),
    email: yup
      .string()
      .email(help.INVALID_EMAIL_ADDRESS)
      .matches(regularExpressions.EMAIL_REGEX, help.INVALID_EMAIL_ADDRESS)
      .required("Email " + help.VALUE_REQUIRED),
    country: yup.string().required("Country " + help.VALUE_REQUIRED),
    password: yup.string().when("newPassword", {
      is: (val: string | null) => !!val,
      then: (allSchema: yup.StringSchema) =>
        allSchema.required("Password is required when New Password is set"),
      otherwise: (allSchema: yup.StringSchema) => allSchema.notRequired(),
    }),
    newPassword: yup
      .string()
      // .required("Password " + help.VALUE_REQUIRED)
      .matches(regularExpressions.PASSWORD_REGEX, help.PASSWORD_HELP),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), ""], help.PASSWORD_MISMATCH),
    // .required("confirm password " + help.VALUE_REQUIRED),
  });

  // const CompanySchema = yup.object({
  //   companyName: yup.string().required("Company Name " + help.VALUE_REQUIRED),
  //   companyLogo: yup.mixed().required("Company Logo " + help.VALUE_REQUIRED),
  //   companyRegistrationNo: yup.string().required("Company Register No. " + help.VALUE_REQUIRED),
  //   country: yup.string().required("Country " + help.VALUE_REQUIRED),
  // });

  // const PasswordSchema = yup.object({
  //   newPassword: yup
  //     .string()
  //     .required("Password " + help.VALUE_REQUIRED)
  //     .matches(regularExpressions.PASSWORD_REGEX, help.PASSWORD_HELP),
  //   confirmPassword: yup
  //     .string()
  //     .oneOf([yup.ref("newPassword"), ""], help.PASSWORD_MISMATCH)
  //     .required("confirm password " + help.VALUE_REQUIRED),
  // });

  const onSubmit = async (values: any) => {
    setEditState(true);
    try {
      const formData = new FormData();
      formData.append("first_name", values.name.split(" ")[0]);
      console.log(values.name.split(" ")[0]);
      formData.append("last_name", values.name.split(" ")[1]);
      formData.append("phone_number", values.phoneNumber);
      formData.append("company_name", values.companyName);
      formData.append("company_country", values.country);
      formData.append("new_password", values.newPassword);
      formData.append("current_password", values.password);
      formData.append("logo", values.companyLogo); // Assuming companyLogo is a File object

      const response = await axios.put(
        `${apiUrls.BASEURL}${apiUrls.USER}/update-profile`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ); // Assuming Register function handles FormData
      console.log(response.data);
      enqueueSnackbar(response.data.message, {variant:"success"});
      setUserAvatar(values.companyLogo);
      setUserHeaderName(values.firstName);
      fetchAdmin();
    } catch (error: any) {
      if (error.response && error.response.data) {
        const errMsg = error.response.data.error;
        console.log(error.response.data.error);
        setErrorMsg(errMsg);
      } else {
        console.error("Error registering user:", error);
        enqueueSnackbar(error.message, {variant:'error'});
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // or any loading spinner you prefer
  }

  return (
    <Formik
      initialValues={{
        name: `${admin.first_name} ${admin.last_name}`,
        phoneNumber: admin.phone_number,
        email: admin.email_address,
        companyName: company.company_name,
        companyLogo: null,
        companyRegistrationNo: company.company_reg_no,
        companyCode:company.company_code,
        country: company.company_country,
        password: "",
        newPassword: "",
        confirmPassword: "",
      }}
      validationSchema={allSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Box justifyContent={"center"}>
            <Box
              sx={{
                background:
                  "linear-gradient(90deg, #FFF2FD 0%, #F3F0FF 51.5%, #E8F9FF 100%)",
                height: "200px",
              }}
            ></Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-50px",
                  left: "10%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={preview ? preview.toString() : "/broken-image.jpg"}
                  sx={{
                    height: "160px",
                    width: "160px",
                    border: "4px solid #FFFFFF",
                    boxShadow: "0px 2px 5px 0px #0000001F",
                  }}
                />
                <CompanyNameText
                  sx={{
                    fontSize: "32px",
                    paddingTop: "30px",
                    paddingLeft: "50px",
                  }}
                >
                  {company.company_name}
                </CompanyNameText>
              </Box>

              <Grid container sx={{ width: "90%" }}>
                <Grid item sx={{ paddingTop: "30px", width: "100%" }}>
                  <Grid container justifyContent={"right"}>
                    <Grid item>
                      <StyledButton3
                        onClick={() => {
                          setEditState(false);
                        }}
                      >
                        Edit profile
                      </StyledButton3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "75px", width: "100%" }}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <CompanyNameText sx={{ fontSize: "20px" }} gutterBottom>
                        Company Profile
                      </CompanyNameText>
                    </Grid>
                    <Grid item>
                      <DetailFormText>
                        Update your company photo and details here.
                      </DetailFormText>
                    </Grid>
                  </Grid>
                  <Divider sx={{ border: " 0.5px solid #00000033" }} />
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "15px" }}>
                  <Grid container alignItems={"center"} rowSpacing={"30px"}>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Company Logo</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <GridContainer mb={"10px"}>
                        <GridItem xs={4.5} md={4.5}>
                          <Avatar
                            src={
                              preview ? preview.toString() : "/broken-image.jpg"
                            }
                            sx={{
                              height: "96px",
                              width: "96px",
                              border: "4px solid #FFFFFF",
                              boxShadow: "0px 2px 5px 0px #0000001F",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={7.5} md={7.5}>
                          <UploadTextField
                            name="companyLogo"
                            setPreview={setPreview}
                          />
                        </GridItem>
                      </GridContainer>
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Company Name</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="companyName"
                        type="text"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Company Reg No.</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="companyRegistrationNo"
                        type="text"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Company code</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="companyCode"
                        type="text"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Country</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      {/* <InputField2 name="country" type="text" /> */}
                      <CountrySelect2 name="country" disabled={editState} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "42px", width: "100%" }}>
                  <Divider sx={{ border: " 0.5px solid #00000033" }} />
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "42px", width: "100%" }}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <CompanyNameText sx={{ fontSize: "20px" }} gutterBottom>
                        Profile info
                      </CompanyNameText>
                    </Grid>
                    <Grid item>
                      <DetailFormText>
                        Update your personal details here.
                      </DetailFormText>
                    </Grid>
                  </Grid>
                  <Divider sx={{ border: " 0.5px solid #00000033" }} />
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "42px" }}>
                  <Grid container alignItems={"center"} rowSpacing={"30px"}>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Name</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="name"
                        type="text"
                        disabled={editState}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Email</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2 name="email" type="email" disabled={true} />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Phone number</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <PhoneField2
                        name="phoneNumber"
                        type="tel"
                        disabled={editState}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "42px", width: "100%" }}>
                  <Grid container direction={"column"}>
                    <Grid item>
                      <CompanyNameText sx={{ fontSize: "20px" }} gutterBottom>
                        Change password
                      </CompanyNameText>
                    </Grid>
                  </Grid>
                  <Divider sx={{ border: " 0.5px solid #00000033" }} />
                </Grid>
                <Grid item md={12} sx={{ paddingTop: "42px" }}>
                  <Grid container alignItems={"center"} rowSpacing={"30px"}>
                    {(errorMsg.current_password || errorMsg.new_password)  && (
                      <React.Fragment>
                        <Grid item md={5} xs={10} />
                        <Grid item md={3.5} xs={10}>
                          <Alert severity="error">{errorMsg.new_password}{errorMsg.current_password}</Alert>
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Current Password</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="password"
                        type="password"
                        disabled={editState}
                        onFocus={() => setErrorMsg({current_password:"", new_password:''})}
                        error={errorMsg.current_password? true : false}
                        />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> New Password</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="newPassword"
                        type="password"
                        disabled={editState}
                        onFocus={() => setErrorMsg({current_password:"", new_password:''})}
                        error={errorMsg.new_password? true : false}
                      />
                    </Grid>
                    <Grid item md={5} xs={10}>
                      <StyledFormLabel> Confirm Password</StyledFormLabel>
                    </Grid>
                    <Grid item md={3.5} xs={10}>
                      <InputField2
                        name="confirmPassword"
                        type="password"
                        disabled={editState}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    paddingTop: "42px",
                    paddingBottom: "150px",
                    width: "100%",
                  }}
                >
                  <Grid container justifyContent={"right"} direction={"row"}>
                    <Grid item>
                      <StyledButton3
                        onClick={() => {
                          navigate(apiUrls.DASHBOARD);
                        }}
                        style={{ padding: "12px 24px 12px 24px" }}
                      >
                        Cancel
                      </StyledButton3>
                    </Grid>
                    <Grid item sx={{ marginLeft: "12px" }}>
                      <StyledButton4
                        type="submit"
                        disabled={editState}
                        sx={{
                          "&.Mui-disabled": {
                            color: "white",
                          },
                        }}
                      >
                        Save Changes
                      </StyledButton4>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileSettings;
