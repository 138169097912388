import { apiUrls } from '../Utilities/properties';
import axios from 'axios';

const AUTH_API_URL = apiUrls.BASEURL + apiUrls.AUTH;
const USER_API_URL = apiUrls.BASEURL + apiUrls.USER;


const logIn = (credentials: any) => {
    return axios.post(AUTH_API_URL + apiUrls.LOG_IN, credentials);
  };

const Register = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.REGISTER, credentials);
};

const UpdateProfile = (credentials: any) => {
  return axios.put(USER_API_URL + apiUrls.UPDATE, credentials);
};

const VerfityOtp = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.VERIFY_EMAIL, credentials);
};
const ResendOtp = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.RESEND_OTP, credentials);
};

// const UserCheck = (credentials: any) => {
//   return axios.get(USER_API_URL + apiUrls.USER_CHECK, credentials);
// };

const UserCheck = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.USER_CHECK, credentials);
};

const UserCheck2 = (credentials: any) => {
  return axios.post(AUTH_API_URL + apiUrls.USER_CHECK2, credentials);
};

const UserDetails = (credentials: any) => {
  return axios.get(USER_API_URL + apiUrls.USER_DETAILS, credentials);
};

const fetchFiles = (credentials: any) => {
  return axios.get(USER_API_URL + apiUrls.FILE, credentials);
};

const uploadFiles = (credentials: any) => {
  return axios.post(USER_API_URL + apiUrls.UPLOAD, credentials);
};

const deleteFiles = (fileId:number, credentials: any) => {
  return axios.delete(USER_API_URL + apiUrls.DELETE+`/${fileId}`, credentials);
};

  export {
    logIn,
    Register,
    VerfityOtp,
    ResendOtp,
    UserDetails,
    fetchFiles,
    uploadFiles,
    deleteFiles,
    UpdateProfile,
    UserCheck,
    UserCheck2
}